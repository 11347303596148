import React from 'react'

import ProjectCard from '../../ProjectCard/ProjectCard'
import LineBreak from '../../LineBreak/LineBreak'

//Project Images
import chyenePotterLogo from '../../../images/cheyne-potter-logo.png'
import spotifyLogo from '../../../images/spotify.png'
import todoImage from '../../../images/todo.png'
import GoLImage from '../../../images/gameoflife.svg'

import * as styles from './Projects.module.css'

const Projects = () => {
  const projects = [
    {
      id: 2,
      name: 'Cheyne Potter Design',
      img: chyenePotterLogo,
      live: 'https://www.cheynepotter.design/',
    },
    {
      id: 1,
      name: 'Lyrics for Spotify',
      img: spotifyLogo,
      repo: 'https://github.com/RyanHiller/Lyrify',
      live: 'https://lyrify.ryanhiller.dev/',
    },

    {
      id: 3,
      name: 'MERN To-Do List App',
      img: todoImage,
      repo: 'https://github.com/RyanHiller/mern-task-manager',
    },
    {
      id: 4,
      name: "Conway's Game of Life",
      img: GoLImage,
      repo: 'https://github.com/RyanHiller/Game-of-Life-TS',
    },
  ]

  return (
    <div className={`${styles.container} alt`}>
      <h1>PROJECTS</h1>
      <LineBreak />
      <div className={styles.projects}>
        {projects.map(({ id, name, img, repo, live }) => {
          return (
            <ProjectCard
              key={id}
              name={name}
              img={img}
              repo={repo}
              live={live}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Projects
