import React from 'react'
import { Helmet } from 'react-helmet'

/* Sections */
import About from '../components/sections/About/About.js'
import Contact from '../components/sections/Contact/Contact.js'
import Landing from '../components/sections/Landing/Landing.js'
import Proficiencies from '../components/sections/Proficiencies/Proficiencies.js'
import Projects from '../components/sections/Projects/Projects.js'
import Resume from '../components/sections/Resume/Resume.js'
import WhatIDo from '../components/sections/WhatIDo/WhatIDo.js'

import * as styles from './index.module.css'

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <html lang='en' />
        <meta charSet='utf-8' />
        <title>Ryan Hiller - Portfolio</title>
        <meta
          name='description'
          content='The portfolio website of web and software developer Ryan Hiller'
        />
      </Helmet>

      <div className={styles.container}>
        <Landing />
        <About />
        <WhatIDo />
        <Projects />
        <Proficiencies />
        <Resume />
        <Contact />
      </div>
    </main>
  )
}

export default IndexPage
