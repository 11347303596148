import React from 'react'

import { FaChevronDown } from 'react-icons/fa'

import * as styles from './Landing.module.css'

function Landing() {
  const handleClick = () => {
    document
      .getElementById('about')
      .scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick()
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h1 className={styles.name}>
          <span className={styles.textLine}>
            <span>R</span>yan
          </span>
          <span className={styles.textLine}>Hiller</span>
        </h1>

        <h2 className={styles.title}>
          <span className={styles.textLine}>Web & Software </span>
          <span className={styles.textLine}>
            Develope<span>r</span>
          </span>
        </h2>
      </div>

      <div
        className={styles.moreButton}
        role='button'
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        <h1>Learn More</h1>
        <FaChevronDown />
      </div>
    </div>
  )
}

export default Landing
