import React from 'react'

import * as styles from './About.module.css'

//import LineBreak from '../../../images/linebreak.svg'
import LineBreak from '../../LineBreak/LineBreak'

export default function About() {
  return (
    <div className={`${styles.container} alt`} id='about'>
      <h1>A Bit About Myself</h1>
      <LineBreak />
      <p>
        I am a Colorado native who had lived in the Denver area for practically
        my entire life until 2021, when I moved to Colorado Springs. I have a
        strong passion for learning new, innovative ways of creating software,
        specifically in a web environment. I currently primarily use React and
        Node to create intuitive applications and websites such as this one!
        Looking forward to hearing from you.
      </p>
    </div>
  )
}
