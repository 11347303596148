import React from 'react'
import LineBreak from '../../LineBreak/LineBreak'

import * as styles from './Resume.module.css'

const Resume = () => {
  return (
    <div className={`${styles.container} alt`}>
      <h1>Resume Highlights</h1>
      <LineBreak />
      <h2>Professional</h2>
      <div className={styles.section}>
        <div className={styles.item}>
          <p>Freelance Web/Software Developer and C.S. Tutor</p>
          <p>Remote/Colorado</p>
          <p>2018 - Present</p>
        </div>
      </div>
      <h2>Education</h2>
      <div className={styles.section}>
        <div className={styles.item}>
          <p>B.S. Computer Science</p>
          <p>Metropolitan State University of Denver</p>
          <p>Spring 2020</p>
        </div>
        <div className={styles.item}>
          <p>Associate of Science</p>
          <p>Community College of Denver</p>
          <p>Fall 2017</p>
        </div>
      </div>
    </div>
  )
}

export default Resume
