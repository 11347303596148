import React from 'react'
import LineBreak from '../../LineBreak/LineBreak'

import {
  FaGithub as GHIcon,
  FaPaperPlane as EmailIcon,
  FaLinkedin as LIIcon,
} from 'react-icons/fa'

import * as styles from './Contact.module.css'

const Contact = () => {
  return (
    <div className={styles.container}>
      <h1>Get In Touch</h1>
      <LineBreak />
      <div className={styles.cardList}>
        <a className={styles.card} href='mailto:ryanhillerdev@gmail.com'>
          <EmailIcon size='1.5rem' />
          <p>ryanhillerdev@gmail.com</p>
        </a>
        <a className={styles.card} href='https://github.com/ryanhiller'>
          <GHIcon size='1.5rem' />
          <p>github.com/ryanhiller</p>
        </a>
        <a className={styles.card} href='https://linkedin.com/in/ryan-hiller'>
          <LIIcon size='1.5rem' />
          <p>linkedin.com/in/ryan-hiller</p>
        </a>
      </div>
    </div>
  )
}

export default Contact
