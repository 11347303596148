import React from 'react'

import LineBreak from '../../LineBreak/LineBreak'

import gears from '../../../images/gears.svg'
import webdev from '../../../images/webdev.svg'
import headphones from '../../../images/headphones.svg'

import * as styles from './WhatIDo.module.css'

const WhatIDo = () => {
  return (
    <div className={styles.container}>
      <h1>What I Do</h1>
      <LineBreak />
      <div className={styles.topicList}>
        <div className={styles.topic}>
          <img src={webdev} alt='' />
          <h2>Software Engineer</h2>
          <p>
            I create high quality websites built with contemporary user
            interfaces using modern back end technologies.
          </p>
        </div>
        <div className={styles.topic}>
          <img src={gears} alt='' />
          <h2>Technologies</h2>
          <p>
            I currently primarily work with the following technologies:
            HTML/CSS, JavaScript, React, Node, and MongoDB.
          </p>
        </div>
        <div className={styles.topic}>
          <img src={headphones} alt='' />
          <h2>Personal Interests</h2>
          <p>
            Much of my free time is spent with my family. I love the outdoors,
            discovering new music, and playing video games with friends.
          </p>
        </div>
      </div>
    </div>
  )
}

export default WhatIDo
