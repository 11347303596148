import React from 'react'
import LineBreak from '../../LineBreak/LineBreak'

// Proficiency badges
import HTMLBadge from '../../../images/html.svg'
import CSSBadge from '../../../images/css.svg'
import JSBadge from '../../../images/js.svg'
import ReactBadge from '../../../images/react.svg'
import NodeBadge from '../../../images/node.svg'
import PythonBadge from '../../../images/python.svg'

import * as styles from './Proficiencies.module.css'

const Proficiencies = () => {
  const proficiencyImages = [
    { img: HTMLBadge, name: 'HTML' },
    { img: CSSBadge, name: 'CSS' },
    { img: JSBadge, name: 'JS' },
    { img: ReactBadge, name: 'React' },
    { img: NodeBadge, name: 'Node' },
    { img: PythonBadge, name: 'Python' },
  ]

  return (
    <div className={styles.container}>
      <h1>MY PROFICIENCIES</h1>
      <LineBreak />
      <div className={styles.proficiencyList}>
        {proficiencyImages.map((prof, idx) => {
          return (
            <img
              className={styles.proficiency}
              src={prof.img}
              alt={prof.name}
              key={idx}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Proficiencies
