import React from 'react'

import * as styles from './ProjectCard.module.css'

const ProjectCard = (props) => {
  const links = props.name ? (
    <div className={styles.links}>
      {props.repo ? <a href={props.repo}>Code</a> : undefined}{' '}
      {props.live ? <a href={props.live}>Live</a> : undefined}
    </div>
  ) : undefined

  const image = props.img ? (
    <img className={styles.image} src={props.img} alt={props.name} />
  ) : undefined

  return (
    <div className={styles.container}>
      <h2 className={styles.name}>{props.name || 'Coming Soon!'}</h2>
      {image}
      {links}
    </div>
  )
}

export default ProjectCard
