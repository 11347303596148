import React from 'react'

import image from '../../images/linebreak.svg'

import * as styles from './LineBreak.module.css'

const LineBreak = () => {
  return <img className={styles.lineBreak} src={image} alt='' />
}

export default LineBreak
